import axios from "axios";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";

const FormToggle = ({ open, setOpen }) => {
	const [scroll, setScroll] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			setScroll(
				window.innerHeight + window.pageYOffset >=
					document.body.offsetHeight - 200
			);
		};

		window.addEventListener("scroll", handleScroll);

		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<button
			type="button"
			className={` fixed flex items-center bottom-6 right-6 gap-2 text-white text-[14px] leading-[24px] font-medium bg-[#232323] rounded-[180px] py-3 px-6 z-20 form-toggle ${
				scroll ? "hide" : ""
			}`}
			open={open}
			onClick={() => setOpen(!open)}
		>
			Enquire now
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="16"
				height="16"
				fill="none"
			>
				<path
					stroke="#fff"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="1.5"
					d="M3.333 8h9.333m0 0L8 3.335m4.666 4.667L8 12.667"
				></path>
			</svg>
		</button>
	);
};

const Overlay = ({ open, setOpen }) => {
	return (
		<div
			className={`panel-nav--overlay ${open && "open"}`}
			onClick={() => setOpen(!open)}
			aria-hidden="true"
		></div>
	);
};

const ProjectForm = ({ open, setOpen, salesforceID }) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		mode: "onSubmit",
		defaultValues: {
			email: "",
		},
	});

	const [serverState, setServerState] = useState({
		submitting: false,
		status: null,
	});

	const handleServerResponse = (ok, msg, form) => {
		setServerState({
			submitting: false,
			status: { ok, msg },
		});
	};
	const handleOnSubmit = (data, e) => {
		e.preventDefault();
		const form = e.target;
		setServerState({ submitting: true });

		const formData = new FormData(form);
		formData.append(
			"g-recaptcha-response",
			serverState["g-recaptcha-response"]
		);

		axios({
			method: "post",
			url: "https://getform.io/f/8770561e-17b1-4782-bf5d-ac77dfd84c2c",
			data: formData,
		})
			.then((r) => {
				handleServerResponse(true);
			})
			.catch((r) => {
				handleServerResponse(false);
			});
	};

	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;

	const onVerify = React.useCallback(
		(token) => {
			setServerState((prevState) => ({
				...prevState,
				"g-recaptcha-response": token,
			}));
		},
		[setServerState]
	);

	return (
		<>
			<div
				className={`panel-nav panel-nav--project h-screen ` + (open && " open")}
				open={open}
			>
				<>
					{serverState.status && serverState.status.ok ? (
						<>
							<div className="md:max-w-[80%]">
								<h3 className="text-lg font-serif mb-4">
									Thank you for your enquiry
								</h3>
								<p>
									A member of the Aria team will be in touch shortly. We
									appreciate your patience.
								</p>
							</div>
						</>
					) : (
						<>
							<h3 className="text-lg font-serif">Find out more</h3>
							<form
								className="w-full my-3"
								onSubmit={handleSubmit(handleOnSubmit)}
							>
								<div>
									<input type="hidden" name="property" value={salesforceID} />
								</div>
								<div className="md:flex md:gap-4">
									<input
										placeholder="First Name*"
										{...register("firstName", { required: true })}
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
											errors.firstName ? "border-red-500" : ""
										}`}
									/>
									<input
										placeholder="Last Name*"
										{...register("lastName", { required: true })}
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
											errors.lastName ? "border-red-500" : ""
										}`}
									/>
								</div>
								<div>
									<input
										placeholder="Email*"
										{...register("email", {
											required: true,
											pattern: emailRegex,
										})}
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
											errors.email ? "border-red-500" : ""
										}`}
									/>
								</div>
								<div className="md:flex md:gap-4">
									<input
										type="text"
										placeholder="Phone*"
										{...register("phone", {
											required: true,
										})}
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
											errors.phone ? "border-red-500" : ""
										}`}
									/>
									<input
										type="text"
										placeholder="Post Code*"
										{...register("postCode", {
											required: true,
										})}
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black bg-transparent w-full my-2 lg:my-3 focus:outline-none ${
											errors.postCode ? "border-red-500" : ""
										}`}
									/>
								</div>
								<div>
									<textarea
										type="text"
										name="message"
										placeholder="Comments"
										className={`form-control rounded-none border-b border-grey-default font-sans text-base placeholder-black my-2 lg:my-3 bg-transparent w-full h-20 lg:h-28 resize-none focus:outline-none`}
									/>
								</div>
								<div>
									<label className="inline-flex items-start cursor-pointer mt-3 mb-6">
										<input type="hidden" name="subscribe" value="no" />
										<input
											type="checkbox"
											name="subscribe"
											value="yes"
											{...register("subscribe", {
												required: true,
											})}
										/>
										<span
											className={`text-sm ${
												errors.subscribe ? "text-red-500" : ""
											}`}
										>
											I consent to receive updates about A.P.G Co.’s products
											and services.
										</span>
									</label>
								</div>
								<input type="hidden" name="_gotcha" className="!hidden" />
								<GoogleReCaptcha onVerify={onVerify} />
								<button
									className="bg-[#232323] text-white font-medium font-sans text-[14px] leading-[24px] w-full p-4 rounded-lg relative transition-colors duration-200 ease-in-out justify-between flex cursor-pointer items-center"
									type="submit"
									disabled={serverState.submitting}
								>
									{serverState.submitting ? "Submitting" : "Submit"}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="16"
										height="17"
										fill="none"
									>
										<path
											stroke="#fff"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.5"
											d="M3.333 8.026h9.334m0 0L8 3.359m4.667 4.667L8 12.693"
										></path>
									</svg>
								</button>
								<p className="text-grey-base terms mt-4">
									By submitting your enquiry, you indicate that you have read
									our&nbsp;
									<Link
										className="underline hover:text-grey-base hover:no-underline"
										to="/privacy"
										onClick={() => setOpen(!open)}
									>
										privacy collection notice
									</Link>
									&nbsp;and acknowledge that your personal information will be
									handled in accordance with that notice and our&nbsp;
									<a
										className="underline hover:text-grey-base hover:no-underline"
										target="_blank"
										rel="noreferrer"
										href="/Aria-Privacy-Policy.pdf"
									>
										privacy policy
									</a>
								</p>
							</form>
							{serverState.status && !serverState.status.ok && (
								<p className="text-lg font-serif text-red-500">
									We're having an issue submitting form
								</p>
							)}
						</>
					)}
				</>
				<button
					className="absolute right-5 md:right-8"
					open={open}
					onClick={() => setOpen(!open)}
				>
					<span className="sr-only">Close</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						fill="none"
						viewBox="0 0 20 20"
					>
						<path stroke="#000" d="M1.188 1.146l18 18m-18.708 0l18-18"></path>
					</svg>
				</button>
			</div>
			<FormToggle open={open} setOpen={setOpen} />
			<Overlay open={open} setOpen={setOpen} />
		</>
	);
};

export default ProjectForm;
