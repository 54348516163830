import { Link } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import React, { useState, useEffect, useRef } from "react";
import PortableText from "react-portable-text";

const ProjectTypeLink = ({ item }) => (
	<>
		<li>
			<Link to={`/projects/${item.slug.current}`}>{item.title}</Link>
		</li>
		<li>/</li>
	</>
);

const ProjectBanner = ({
	projectType,
	title,
	_rawExcerpt,
	mainImage,
	open,
	setOpen
}) => {

	const imageBannerRef = useRef();

	return (
		<div className="grid md:grid-cols-12 mb-4 md:mb-0">
			<div className="bg-off-white order-2 md:order-1 md:col-span-4 p-6 md:p-10 flex flex-col">
				<ul className="flex gap-2 text-[14px] leading-[24px] mb-6 md:mb-10">
					<li>
						<Link to="/projects">Our projects</Link>
					</li>
					<li>/</li>
					{projectType.map((item, index) => (
						<ProjectTypeLink item={item} key={index} />
					))}{" "}
				</ul>
				<div className="mt-auto">
					{title && (
						<h1 className="text-[32px] leading-[1.2] font-serif">{title}</h1>
					)}
					{_rawExcerpt && (
						<PortableText
							className="text-[14px] leading-[24px] text-[#666] my-6 md:my-10"
							content={_rawExcerpt}
							serializers={{
								internalLink: ({ reference, children }) => {
									const href = `/${reference._type}/${reference.slug.current}`;
									return <Link to={href}>{children}</Link>;
								},
								link: ({ href, blank, children }) => {
									return (
										<a
											href={href}
											target={blank ? "_blank" : "_self"}
											rel={blank ? "noreferrer" : undefined}
										>
											{children}
										</a>
									);
								},
							}}
						/>
					)}
					<button
						type="button"
						className="link text-[#1B1B1B] text-[14px]"
						open={open}
						onClick={() => setOpen(!open)}
					>
						Enquire now
					</button>
				</div>
			</div>
			<div className="md:col-span-8 order-1 md:order-2">
				{mainImage && mainImage.asset && (
					<Image
						{...mainImage}
						width={1600}
						height={880}
						alt={mainImage.alt}
						className={
							"aspect-[1087/779] object-cover object-center h-full lazy loaded"
						}
					/>
				)}
			</div>
		</div>
	);
};

export default ProjectBanner;
