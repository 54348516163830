import React from "react";
import { graphql } from "gatsby";
import Container from "../components/Container/Container";
import GraphQLErrorList from "../components/graphql-error-list";
import ProjectPost from "../components/ProjectPost/ProjectPost";
import Seo from "../components/SEO/SEO";
import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query ProjectTemplateQuery($id: String!) {
    project: sanityProject(id: { eq: $id }) {
      id
      salesforceID
      _rawExcerpt
      mainImage {
        ...Image
        alt
      }
      title
      slug {
        current
      }
      projectType {
        title
        slug {
          current
        }
      }
      pType
      simpleContent {
        _rawBody
        _rawLocation
        slideshow {
          images {
            ...Image
            asset {
              metadata {
                dimensions {
                  width
                }
              }
            }
          }
        }
        related {
          _key
          _type
          title
          tagline
          projects {
            _rawExcerpt
            previewImage {
              ...Image
              alt
            }
            slug {
              current
            }
            title
          }
        }
      }
      content {
        ... on SanityCatalogueFullWidth {
          _key
          _type
          media
          videoUrl
          video {
            asset {
              url
            }
          }
          image {
            ...Image
            alt
          }
        }
        ... on SanityCatalogueText {
          _key
          _type
          alignment
          _rawBasic
        }
        ... on SanityCatalogueThreeQuarter {
          _key
          _type
          alignment
          media
          videoUrl
          video {
            asset {
              url
            }
          }
          mainImage {
            ...Image
            alt
          }
        }
        ... on SanityCatalogueTwoPortrait {
          _key
          _type
          alignment
          leftImage {
            ...Image
            alt
          }
          rightImage {
            ...Image
            alt
          }
        }
        ... on SanityGlance {
          _key
          _type
          details {
            _key
            _type
            description
            title
          }
          _rawAwards
          stats {
            _key
            _type
            amount
            separator
            suffix
            title
          }
        }
        ... on SanityImageTextBlock {
          _key
          _type
          imageDirection
          imageType
          layout
          link
          linkText
          title
          description
          media
          videoUrl
          image {
            ...Image
            alt
          }
        }
        ... on SanityMediaAwards {
          _key
          _type
          items {
            _key
            _type
            detailType
            link
            title
            year
          }
        }
        ... on SanityRelatedProjects {
          _key
          _type
          projects {
            _rawExcerpt
            previewImage {
              ...Image
              alt
            }
            slug {
              current
            }
            title
          }
        }
        ... on SanityEnquiry {
          _key
          _type
          title
          tagline
          image {
            ...Image
          }
        }
        ... on SanityTimeline {
          _key
          _type
          title
          introduction
          slides {
            ...Image
            alt
            asset {
              metadata {
                dimensions {
                  width
                }
              }
            }
          }
        }
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      enquiryCTAImage {
        ...Image
      }
      enquiryCTATitle
      enquiryCTAText
    }
  }
`;

const ProjectTemplate = (props) => {
  const { data, errors } = props;
  const project = data && data.project;
  const siteSettings = data && data.site;
  return (
    <>
      {errors && <Seo title="GraphQL Error" />}
      {errors && <Seo title="GraphQL Error" />}
      {project && (
        <Seo
          title={project.title || "Untitled"}
          description={toPlainText(project._rawExcerpt)}
          image={project.mainImage}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {project && <ProjectPost {...project} siteSettings={siteSettings} />}
    </>
  );
};

export default ProjectTemplate;
